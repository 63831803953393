import React from "react";
import deepEqual from "dequal";
export function checkDeps(deps, name) {
  const reactHookName = `React.${name.replace(/DeepCompare/, "")}`;

  if (!deps || deps.length === 0) {
    throw new Error(`${name} should not be used with no dependencies. Use ${reactHookName} instead.`);
  }
}
export function useDeepCompareMemoize(value) {
  const ref = React.useRef([]);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}