import React from 'react';
import { checkDeps, useDeepCompareMemoize } from "./useDeepCompareMemoize.js";
/**
 * `useDeepCompareEffect` Accepts a function that contains imperative, possibly
 * effectful code.
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list
 * change.
 *
 * Usage note: only use this if `deps` are objects or arrays that contain
 * objects. Otherwise you should just use React.useEffect.
 *
 */

function useDeepCompareEffect(effect, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareEffect');
  }

  React.useEffect(effect, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareEffect;